export default function (type = 1) {
    let window = 'processo.new'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Adicionar Processo',
        width: '600',
        height: '400',
        backdrop: false,
        move: true,
        clickOutside: true,
        windowClass: 'erp-window-modern v2',
        contentClass: '',
        props: {
            router: this.$router,
            // type: type === 1 || type === 2 ? type : 1
        }
    }, () => import('./SimpleNewProcesso'))
        .then((wid) => {
            console.log(wid)
            this.$uloc.window.listen(wid, {
                created: (wid, data) => {
                    console.log('Simple Processo created event')
                    this.onCreated && this.onCreated(data)
                    // this.$router.push({name: 'processo.show', params: {id: data.id}, query: {new: 1}})
                }
            })
        }) // return wid
}
