<script>
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage} from 'uloc-vue'
import {
  EBtnTableOptions,
  // EBtnCircleStatus
} from 'uloc-vue-plugin-erp'
import {ErpInput, ErpSelect, ErpSField} from 'uloc-vue-plugin-erp'
import {datePtToEn} from '@/utils/date'
import DefaultMixin from '../../mixin'
import SearchFilter from "../../../layout/helpers/SearchFilter"
import DateInput from "../../../../reuse/input/Date"
import MenuOptions from "../../../layout/context-menu/context-window-options"
import windowProcesso from './window/windowProcesso'
import simpoeNewProcesso from './window/simpoeNewProcesso'
import Menu from '../menu/menu'
import {list, deleteProcesso} from '@/domain/processos/services'
import MenuOptionsItem from "../../../layout/context-menu/context-window-options-item"
import resolver from './resolver'
import TipoProcessoSelect from "./include/TipoProcessoSelect"
import tableColumnsCache from "@/utils/tableColumnsCache"
import listOptions from "@/components/bem/windows/listOptions"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import StatusProcessoSelect from "@/components/processos/components/processos/include/StatusProcessoSelect"
import TribunalSelect from "@/components/processos/components/processos/include/TribunalSelect"
import ComarcaSelect from "@/components/processos/components/processos/include/ComarcaSelect"
import VaraSelect from "@/components/processos/components/processos/include/VaraSelect"
import {TIPOS_PROCESSO} from "@/domain/processos/helpers/tipo-processo"
import FinPessoaInput from "@/components/financeiro/components/input/PessoaInput"

let filters = {
  id: '',
  tipo: '',
  search: '',
  partes: '',
  cliente: '',
  numero: '',
  data1: '',
  data2: '',
  tipoData: 'publicacao',
  status: null,
  tribunal: null,
  comarca: null,
  vara: null,
  // uf: '',
}

let listStorage = tableColumnsCache('processos.lista', resolver.columns, 1)

export default {
  name: 'Processos',
  mixins: [DefaultMixin],
  components: {
    FinPessoaInput,
    VaraSelect,
    ComarcaSelect,
    TribunalSelect,
    StatusProcessoSelect,
    ECol,
    ERow,
    TipoProcessoSelect,
    MenuOptionsItem,
    MenuOptions,
    DateInput,
    SearchFilter,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSField,
    ErpSelect
    // EBtnCircleStatus
  },
  data () {
    return {
      listStorage: listStorage,
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      cacheMemoization: {}
    }
  },
  computed: {
    visibledColumns () {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    },
    tiposProcesso () {
      return TIPOS_PROCESSO
    }
  },
  mounted () {
    console.log('xxx')
    this.load()
  },
  destroyed () {
  },
  methods: {
    request ({pagination, filter}) {
      let data1 = ''
      let data2 = ''
      let extraFilters = []
      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        extraFilters.push(`&data1=${data1}`)
        extraFilters.push(`&data2=${data2}`)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
      }

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.uf && extraFilters.push(`&uf=${this.table.filters.uf}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status}`)
      this.table.filters.partes && extraFilters.push(`&partes=${this.table.filters.partes}`)
      this.table.filters.cliente && extraFilters.push(`&cliente=${this.table.filters.cliente.id ?? this.table.filters.cliente}`)
      this.table.filters.tribunal && extraFilters.push(`&tribunal=${this.table.filters.tribunal}`)
      this.table.filters.comarca && extraFilters.push(`&comarca=${this.table.filters.comarca}`)
      this.table.filters.vara && extraFilters.push(`&vara=${this.table.filters.vara}`)
      this.table.filters.tipoData && extraFilters.push(`&tipoData=${this.table.filters.tipoData}`)

      // let numero = String(this.table.filters.numero).replace(/\D/gi, '') // TODO: Only numbers
      let numero = String(this.table.filters.numero)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${this.table.filters.search}&numero=${numero}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    simpoeNewProcesso,
    novo () {
      console.log('New...')
      // this.windowProcesso()
      this.simpoeNewProcesso()
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    abrir (id) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'processo', params: {id: id}})
      window.open(routeData.href, '_blank')
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este processo? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir processo-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteProcesso(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Processo excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    // TODO: criar um nome melhor
    /* stringifyCollectionsPersonNames (id, array = []) {
      if (typeof this.cacheMemoization[id] !== 'undefined') return this.cacheMemoization[id]
      const result = (array || []).map(a => a.name).join(' / ')
      this.cacheMemoization[id] = result
      return result
    }, */
    windowProcesso,
    listOptions: listOptions,
    updateListDefinition (newConfig) {
      LocalStorage.set('processos.lista', newConfig)
      this.listStorage = newConfig
    },
  },
  meta: {
    title: 'Processos',
    name: 'Processos'
  }
}
</script>

<template>
    <div class="default-list__">
      <div class="wrapper-md" style="padding-bottom: 0">
        <p class="font-16 font-bold">Processos</p>
      </div>
      <search-filter>
        <form class="m-t-sm">
          <e-row class="e-input-modern size1 def-m-r">
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Processo / Pesquisa inteligente"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="tl"
                  label="Número do processo"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F4" v-model="table.filters.numero" />
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="tl"
                  label="Partes"
              >
                <erp-input v-on:keyup.enter="pesquisar" v-model="table.filters.partes" />
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="tl"
                  label="Cliente"
              >
                <fin-pessoa-input @keydown.enter.native="pesquisar" v-model="table.filters.cliente" />
              </erp-s-field>
            </e-col>

          </e-row>

          <e-row class="e-input-modern size1" mr>
            <e-col style="max-width: 200px">
              <tribunal-select v-model="table.filters.tribunal" />
            </e-col>

            <e-col style="max-width: 300px">
              <comarca-select v-model="table.filters.comarca" :tribunal="table.filters.tribunal" :limit="10000"/>
            </e-col>

            <e-col>
              <vara-select v-model="table.filters.vara" :comarca="table.filters.comarca"  />
            </e-col>
          </e-row>

          <e-row class="e-input-modern size1" mr>
            <e-col style="min-width: 126px; max-width: 126px">
              <tipo-processo-select select-null v-model="table.filters.tipo" />
            </e-col>
            <e-col style="min-width: 126px; max-width: 126px">
              <status-processo-select select-null v-model="table.filters.status" />
            </e-col>
            <e-col style="min-width: 146px; max-width: 146px">
              <date-input label="Data (De)" v-model="table.filters.data1" />
            </e-col>

            <e-col class="col" style="min-width: 146px; max-width: 146px">
              <date-input label="Data (Até)" v-model="table.filters.data2" />
            </e-col>

            <e-col class="col" style="min-width: 176px; max-width: 176px">
              <erp-s-field
                  view="tl"
                  label="Tipo Data"
              >
                <erp-select
                    placeholder="Qualquer"
                    :options="[
                        {label: 'Última Publicação', value: 'publicacao'},
                        {label: 'Registro', value: 'registro'},
                        {label: 'Leilão Designado', value: 'leilao'},
                    ]"
                    v-model="table.filters.tipoData" />
              </erp-s-field>
            </e-col>
            <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar" />
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters" />
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
              <div class="flex flex-center column">
                <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                       @shortkey.native="novo" />
                <span class="shortcut">Shift + N</span>
              </div>
            </div>
          </e-row>
        </form>
      </search-filter>
      <div class="erp-list">
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th :style="[col.name === 'id' ? ' max-width: 25px' : '', col.name === 'numero' ? ' min-width: 325px' : '']" v-for="col in props.cols" :key="col.name"
                  :props="props"
                  :class="{'table-th-options': col.name === 'options'}"
            >
              <span v-if="col.name !== 'options'">{{ col.label }}</span>
              <span @click="listOptions()" style="flex: 1" v-else><i class="fal fa-cog"></i></span>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td key="numero" :props="props">
              {{props.row.numero | formataNumeroProcesso}}
            </u-td>
            <u-td key="numeroAntigo" :props="props">
              {{props.row.numeroAntigo || '-'}}
            </u-td>
            <u-td key="tipo" :props="props">
              <span v-if="props.row.tipo">{{tiposProcesso[props.row.tipo] && tiposProcesso[props.row.tipo].label}}</span>
            </u-td>
            <u-td key="cliente" :props="props">
              {{props.row.cliente && props.row.cliente.nome}}
            </u-td>
            <u-td key="juizo" :props="props">
              {{props.row.juizo && props.row.juizo.nome}}
            </u-td>
            <u-td key="comarca" :props="props">
              {{props.row.vara && props.row.vara.comarca && props.row.vara.comarca.nome}}
            </u-td>
            <u-td key="vara" :props="props">
              {{props.row.vara && props.row.vara.nome}}
            </u-td>
            <u-td key="ultimaAtualizacao" :props="props">
              <span v-if="props.row.dateModified">{{props.row.dateModified| formatDate}}</span>
            </u-td>
            <u-td key="status" :props="props">
              {{props.row.status && props.row.status.nome}}
            </u-td>
            <u-td key="classificacao" :props="props">
              {{props.row.classificacao ? props.row.classificacao.nome : ''}}
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <ul>
                    <menu-options-item close label="Abrir" @click="abrir(props.row.id)" icon="folder-open" />
                    <menu-options-item close label="Excluir" @click="excluir(props.row.id)" icon="times" />
                    <!--<menu-options-item close label="Excluir" @click="excluir(props.row)" icon="times"
                                       label-class="text-negative" />-->
                  </ul>
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>
      </div>
    </div>
</template>

<style lang="stylus" src="../../../arrematante/assets/default.styl"></style>
