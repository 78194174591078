import {list} from '@/domain/processos/services'
import window from './window/windowProcesso'

export default {
  pageTitle: 'Processos',
  columns: [
    {
      name: 'numero',
      label: 'Processo',
      align: 'left',
      field: 'numero',
      sortable: true,
      active: true,
      parser: function (props) {
        return props.numero
      }
    },
    {
      name: 'numeroAntigo',
      label: 'Número Antigo',
      align: 'left',
      field: 'numeroAntivo',
      sortable: true,
      active: false,
      parser: function (props) {
        return props.numeroAntigo
      }
    },
    {
      name: 'tipo',
      label: 'Tipo',
      align: 'left',
      field: 'tipo',
      sortable: true,
      active: true,
      parser: function (props) {
        return props.tipo
      }
    },
    {
      name: 'cliente',
      label: 'Cliente',
      align: 'left',
      field: 'cliente',
      sortable: true,
      active: true,
      parser: function (props) {
        return props.cliente.name
      }
    },
    {
      name: 'juizo',
      label: 'Juízo',
      align: 'left',
      field: 'juizo',
      sortable: true,
      active: false,
      parser: function (props) {
        return props.juizo
      }
    },
    {
      name: 'comarca',
      label: 'Comarca',
      align: 'left',
      field: 'comarca',
      sortable: true,
      active: false,
      parser: function (props) {
        return props.comarca
      }
    },
    {
      name: 'vara',
      label: 'Vara',
      align: 'left',
      field: 'vara',
      sortable: true,
      active: true,
      parser: function (props) {
        return props.vara
      }
    },
    /* {
      name: 'autors',

      label: 'Autor',
      align: 'left',
      field: 'autors',
      sortable: true,
      parser: function (props) {
        return props.autors
      }
    },
    {
      name: 'advogadoAutors',

      label: 'Advogado Autor',
      align: 'left',
      field: 'advogadoAutors',
      sortable: true,
      parser: function (props) {
        return props.advogadoAutors
      }
    },
    {
      name: 'reu',

      label: 'Réu',
      align: 'left',
      field: 'reu',
      sortable: true,
      parser: function (props) {
        return props.numero
      }
    },
    {
      name: 'advogadoReu',

      label: 'Advogado Réu',
      align: 'left',
      field: 'advogadoReu',
      sortable: true,
      parser: function (props) {
        return props.numero
      }
    },
    {
      name: 'penhora',

      label: 'Penhora',
      align: 'left',
      field: 'penhora',
      sortable: true,
      parser: function (props) {
        return props.numero
      }
    }, */
    {
      name: 'ultimaAtualizacao',
      label: 'Última alteração',
      align: 'left',
      field: 'ultimaAtualizacao',
      sortable: true,
      active: true,
      parser: function (props) {
        return props.numero
      }
    },
    {
      name: 'status',
      label: 'Situação',
      align: 'left',
      field: 'status',
      sortable: true,
      active: true,
      parser: function (props) {
        return props.status.nome
      }
    },
    {
      name: 'classificacao',
      label: 'Motivo',
      align: 'left',
      field: 'classificacao',
      sortable: true,
      active: true,
      parser: function (props) {
        return props.classificacao.name
      }
    }
  ],
  resolve: list,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    }
  }
}
